import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import StaffComponent from "@components/Staff";
import SEO from "@components/seo";
import { MetadataService } from "../../utils/MetadataService";

const Staff = ({ uri, data }) => {

  // const { pageMetadata } = data;
  // const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;
  return (
    <Layout path={uri}>
      <SEO title="Our Staff" />
      <StaffComponent />
      <p></p>
    </Layout>
  );
}


export const pageQuery = graphql`
  query StaffComponentQuery {
    pageMetadata(page_id: {eq: "staff"}) {
      content,
      page_id
    }
  }
`

export default Staff;
