import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import * as styles from "./Staff.module.scss";
import slugify from "slugify";

import ContactForm from "@components/ContactForm";

import bunny from "@images/index_page/logo1.svg";

const thomas = {
  fullName: "Peter Antley",
  picture: bunny,
  position: "Co-founder & CEO",
  availability: true,
  skills: [
    {
      title: "Skill One",
      level: "Beginner"
    },
    {
      title: "Another skill",
      level: "Intermediate"
    },
    {
      title: "And more",
      level: "Advanced"
    },
    {
      title: "Here's another",
      level: "Expert"
    }
  ]
};

const SingleStaffCard = ({ name, picture, position, availability, skills }) => {
  const loweredName = name
    .split("")
    .map(letter => {
      if (letter == letter.toUpperCase()) {
        return " " + letter;
      } else {
        return letter;
      }
    }).join("");

  const slug = slugify(loweredName, { lower: true });

  return (
    <article className={styles.staff_card}>
      <Link to={slug}>
        <div className={styles.staff_card_top}>
          <img src={picture[0].image} alt={picture[0].name} />
          <div className={styles.name_and_position}>
            <h2>{name}</h2>
            <h3>{position}</h3>
          </div>
        </div>
        <p>
          <span className={styles.availability_light}></span>
          {availability && "Available"}
        </p>
        <h4>Skills and abilities</h4>
        <ul>
          {skills.map(skill => (
            <li clasName={styles.bullet}>
              {skill.title} - {skill.level[0].title}
            </li>
          ))}
        </ul>
      </Link>
    </article>
  );
};

// Here is a simple example that extracts a single prop.
// const user = {
//   id: 339,
//   name: 'Fred',
//   age: 42
// };
// const {name} = user;
// console.log(name); //prints: Fred

// Here is another example that extracts the props, but assigns it to a different name.
// const user = {
//   id: 339,
//   name: 'Fred',
//   age: 42
// };
// const {name: callSign} = user;
// console.log(callSign); //prints: Fred

// Destructuring also works for nested objects.
// const user = {
//   id: 339,
//   name: 'Fred',
//   age: 42,
//   education: {
//     degree: 'Masters'
//   }
// };
// const {education: {degree}} = user;

const StaffComponent = () => {
  const {
    allStaff: { edges: members }
  } = useStaticQuery(graphql`
    {
      allStaff {
        edges {
          node {
            availability
            id
            name
            picture {
              image
              name
            }
            position
            skills {
              level {
                title
              }
              title
            }
          }
        }
      }
    }
  `);

  return (
    <div className="container">
      <div className={`${styles.header}`}>
        {/* <h1 style={{ display: "block", paddingTop: "145px" }}>Our Staff</h1> */}
      </div>

      <section className={styles.staff_cards_section}>
        {members.map(member => (
          <SingleStaffCard {...member.node} />
        ))}
      </section>
      <section>
        <ContactForm />
      </section>
    </div>
  );
};

export default StaffComponent;
